<template>
  <div ref="tableRootRef" class="table-container" :id="id">
    <div v-if="!noHeader" :style="calcGrid(keys)" class="headers">
      <div v-for="header in keys" :key="header.key" class="item">
        <slot :name="`header-${header.key}`" :value="header">
          <span
            class="content"
            :id="generateId(header.label, header.key)"
            ref="textContainerRef"
            v-tooltip="
              isOverflowing(generateId(header.label, header.key))
                ? {
                    content: header.label,
                    delay: { show: 800, hide: 100 },
                  }
                : null
            "
          >
            {{ $t(header.label) }}
          </span>
        </slot>
        <span v-if="sortColumns.includes(header.key)" class="sorting">
          <ButtonElement @click="$emit('sort-asc', header.key)" link>
            <Arrow
              class="sort-asc"
              :class="{
                'sort-asc--active': sortType === `${header.key}:asc`,
              }"
              direction="top"
              width="20px"
              height="20px"
            />
          </ButtonElement>
          <ButtonElement @click="$emit('sort-desc', header.key)" link>
            <ArrowIcon
              class="sort-desc"
              :class="{
                'sort-desc--active': sortType === `${header.key}:desc`,
              }"
              direction="bottom"
              width="20px"
              height="20px"
            />
          </ButtonElement>
        </span>
      </div>
    </div>

    <div
      v-for="row in data"
      :key="row.id"
      :style="calcGrid(keys)"
      :class="['table-row', { active: active.id == row.id }]"
      @click="select(row)"
    >
      <div
        v-for="(cell, key) in keys"
        :key="cell.key"
        :class="['item', { 'right-align': rightAlign.includes(key) }]"
        :style="calcGrid(keys)"
      >
        <slot
          :name="`cell-${cell.key}`"
          :value="row"
          :header="cell.key"
          :cell="row[cell.key]"
          :row="row"
        >
          <!-- only primitive types are accepted, if reference types, must use slots -->
          <template v-if="cell.combined">
            <div style="display: flex">
              <span
                v-for="(a, b) in cell.combined"
                :key="b"
                :id="generateId(row[cell.key], cell.key)"
                ref="textContainerRef"
                style="margin-right: 4px"
              >
                {{ row[a] }}
              </span>
            </div>
          </template>
          <template v-else>
            <span
              v-if="allowedTypes(row[cell.key])"
              class="content"
              :id="generateId(row[cell.key], cell.key)"
              ref="textContainerRef"
            >
              {{ row[cell.key] }}
            </span>
          </template>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';
import useOverflowText from '../../composables/useOverflowText';
import { convertKeysToHeaders } from '../../utils/helpers';
import ButtonElement from '../FormControl/ButtonElement.vue';
import ArrowIcon from '../Svgs/ArrowIcon.vue';

export default {
  setup(props, ctx) {
    const [isOverflowing, textContainerRef] = useOverflowText();

    const tableRootRef = ref(null);
    const active = ref(false);

    return {
      isOverflowing,
      textContainerRef,
      tableRootRef,
      active,
    };
  },
  components: {
    ArrowIcon,
    ButtonElement,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    id: {
      type: String,
      default: '',
    },
    keys: {
      type: Array,
    },
    actionable: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    sortType: {
      type: String,
      default: '',
    },
    sortColumns: {
      type: Array,
      default: () => [],
    },
    columnWidth: {
      type: Array,
      default: () => [
        // { width: '750px', key: 'recipients', fixed: true },
        // { key: 'reportUrl', width: '450px', fixed: false },
      ],
      validator: val =>
        val.length > 0 ? val.every(x => x.width && x.key) : true,
    },
    noHeader: {
      type: Boolean,
      default: false,
    },
    rightAlign: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    headers() {
      if (this.keys.length > 0) {
        // const headerKeys = Object.keys(this.keys);
        return this.keys.map(item => ({
          key: item.key,
          label: item.label,
        }));
      }

      return [];
    },
  },
  methods: {
    allowedTypes(cell) {
      const allowedTypes = ['string', 'number', 'boolean'];
      return allowedTypes.includes(typeof cell);
    },
    generateId(cell, key) {
      if (typeof cell !== 'string' && this.allowedTypes(cell)) {
        cell = String(cell);
      }
      //return `${key}-${this.id}-${cell.slice(0, 10).toLowerCase()}__text`;
      return `${key}-1`;
    },
    calcGrid(valuesArray) {
      return {
        display: 'grid',
        'grid-template-columns': valuesArray
          .map(item => {
            let width = '200px';
            if (this.columnWidth.length > 0) {
              const result = this.columnWidth.find(x => {
                return item.key === x.key || item[0] === x.key;
              });

              if (result?.width) {
                return result.fixed
                  ? result.width
                  : `minmax(${result.width}, 1fr)`;
              }
            }
            return `minmax(${width}, 1fr)`;
          })
          .join(' '),
        'grid-template-rows': `minmax(60px, auto)`,
      };
    },
    select(item) {
      this.$emit('itemSelect', item);

      if (this.selectable) {
        this.active = this.active === item ? false : item;
        console.log(this.active);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.table-container {
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  height: 100%;
}

.headers {
  overflow-wrap: break-word;
  // background-color: white;
  height: 40px;

  > .item {
    background-color: rgba(229, 132, 19, 0.15);
    color: $hty-orange;
    height: 40px;
    border-bottom: 0;
    display: flex;
    align-items: center;

    &:first-child {
      border-top-left-radius: $hty-radius;
    }
    &:last-child {
      border-top-right-radius: $hty-radius;
    }
  }
}

.item {
  padding: 0 20px;
  display: flex;
  align-items: center;
  height: 60px;
  overflow: hidden;
}

.content {
  white-space: nowrap;
}

.table-row {
  overflow-wrap: break-word;
  background-color: white;
  cursor: pointer;
  // overflow-y: auto;
  // height: 100%;

  &.active {
    .item {
      background: #f9ebda !important;
      transition: 0.1s background;
    }
  }

  &:nth-child(odd) {
    > .item {
      background-color: #fafafa;
    }
  }
  &:nth-child(even) {
    > .item {
      background-color: #f4f4f4;
    }
  }

  .right-align {
    justify-content: flex-end;
  }
}

.sorting {
  position: relative;
  top: 4px;
  padding-left: 5px;

  .sort-asc--active {
    ::v-deep path {
      stroke: $hty-black;
    }
  }
  .sort-desc--active {
    ::v-deep path {
      stroke: $hty-black;
    }
  }
}

.header {
}
.body {
}
</style>
