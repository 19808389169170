<template>
  <div class="header mb-3">
    <div class="row" style="margin: 0 -15px">
      <div class="btn1 col-12 col-md-6 col-xl-auto">
        <slot name="button-1"></slot>
      </div>
      <div class="btn2 col-12 col-md-6 col-xl-auto">
        <slot name="button-2"></slot>
      </div>
      <div class="col d-none d-xl-flex"></div>
      <div class="col-12 col-md-8 col-xl-auto mt-3 mt-xl-0">
        <Search
          class="search-input mr-3"
          :placeholder="searchLabel"
          style="width: 100%"
          @search-input="handleSearch"
        />
      </div>
      <div class="col-12 col-md-4 col-xl-auto mt-3 mt-xl-0">
        <SelectElement
          class="sort"
          :placeholder="$t('serviceProvider.settingsServices.sortBy')"
          background-color="grey"
          :options="sortingOptions"
          v-model="selectedSort"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Search from '@/components/Staff/SearchCustom';
import SelectElement from '@/components/Staff/SelectElementCustom';

export default {
  name: 'TableHeaderCustom',

  components: {
    Search,
    SelectElement,
  },

  props: {
    mode: {
      type: String,
    },
    settings: {
      type: Object,
    },
    sortingOptions: {
      type: Array,
    },
    searchLabel: {
      type: String,
    },
  },

  data() {
    return {
      selectedSort: '',
    };
  },
  methods: {
    handleSearch(dd) {
      console.log('handleSearch', dd);
      this.$emit('search', dd);
    },
  },
  watch: {
    selectedSort(a) {
      this.$emit('sort', a);
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  .btn1,
  .btn2 {
    button {
      width: 100% !important;
    }
  }

  @media screen and (max-width: 767px) {
    .btn2 button {
      margin-top: 16px;
    }
  }
}
</style>
