<template>
  <div class="">
    <form class="search" @submit.prevent="handleSubmit">
      <SearchIcon />
      <InputElement
        v-model="searchInput"
        class="search-input"
        focus
        :placeholder="placeholder"
        @keyup="handleKeyup"
      />
      <OutsideClickContainer @outsideClick="isOpen = false" :active="isOpen">
        <DropdownElement v-if="isOpen" top="60px" width="100%">
          <div class="dropdown">
            <div class="dropdown-title">
              {{
                $t(
                  `${$store.getters.getTranslationKeyForAdmin}.searchFunctions.searchResult`
                )
              }}
            </div>
            <div
              v-for="item of dropdownItems"
              :key="item"
              class="dropdown-item"
              role="button"
              tabindex="0"
              @click="handleSelection"
            >
              <div class="text">{{ item }}</div>
              <div>
                <BannerMessage> {{ item }} </BannerMessage>
              </div>
            </div>
          </div>
        </DropdownElement>
      </OutsideClickContainer>
    </form>
  </div>
</template>

<script>
import { ref, toRef, watchEffect } from '@vue/composition-api';
import InputElement from '../FormControl/InputElement.vue';
import SearchIcon from '../Svgs/SearchIcon.vue';
import DropdownElement from '../Dropdown/DropdownElement.vue';
import BannerMessage from '../Banner/BannerMessage.vue';
import OutsideClickContainer from '../OutsideClickContainer/OutsideClickContainer.vue';

export default {
  setup(props, { emit }) {
    const searchInput = ref('');
    const isOpen = ref(false);

    const dropdownItems = ref([
      'ghkekef',
      'ghkekesdasdadf',
      'jjjsd',
      '4j4jiti',
    ]);

    const handleSubmit = () => {
      isOpen.value = true;
      emit('submit');
    };

    const handleSelection = () => {
      isOpen.value = false;
    };

    const handleKeyup = key => {
      if (key === 'Escape') {
        searchInput.value = '';
        isOpen.value = false;
      } else {
        emit('search-input', searchInput.value);
      }
    };

    return {
      searchInput,
      isOpen,
      dropdownItems,
      handleSubmit,
      handleSelection,
      handleKeyup,
    };
  },
  components: {
    OutsideClickContainer,
    DropdownElement,
    SearchIcon,
    InputElement,
    BannerMessage,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: 'Search',
    },
    filterOptions: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  position: relative;
  width: 100%;
  // margin-left: 20px;

  > :first-child {
    position: absolute;
    left: 1rem;
    top: 0.7rem;
  }

  > .search-input {
    ::v-deep input {
      height: 44px;
      padding-left: 3rem;
      background-color: $hty-grey;
      border-color: $hty-grey;
    }
  }
}
.dropdown {
  padding: 1rem 1.5rem;
}
.dropdown-item {
  display: flex;
  justify-content: space-between;
  &:not(:last-child) {
    padding-bottom: 10px;
  }
}
.dropdown-title {
  border-bottom: 2px solid $hty-orange;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.2;
  color: $hty-grey-text;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}
.text {
  @include hty-ellipsis;
  width: 70%;
}
</style>
