var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"tableRootRef",staticClass:"table-container",attrs:{"id":_vm.id}},[(!_vm.noHeader)?_c('div',{staticClass:"headers",style:(_vm.calcGrid(_vm.keys))},_vm._l((_vm.keys),function(header){return _c('div',{key:header.key,staticClass:"item"},[_vm._t(("header-" + (header.key)),[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
            _vm.isOverflowing(_vm.generateId(header.label, header.key))
              ? {
                  content: header.label,
                  delay: { show: 800, hide: 100 },
                }
              : null
          ),expression:"\n            isOverflowing(generateId(header.label, header.key))\n              ? {\n                  content: header.label,\n                  delay: { show: 800, hide: 100 },\n                }\n              : null\n          "}],ref:"textContainerRef",refInFor:true,staticClass:"content",attrs:{"id":_vm.generateId(header.label, header.key)}},[_vm._v(" "+_vm._s(_vm.$t(header.label))+" ")])],{"value":header}),(_vm.sortColumns.includes(header.key))?_c('span',{staticClass:"sorting"},[_c('ButtonElement',{attrs:{"link":""},on:{"click":function($event){return _vm.$emit('sort-asc', header.key)}}},[_c('Arrow',{staticClass:"sort-asc",class:{
              'sort-asc--active': _vm.sortType === ((header.key) + ":asc"),
            },attrs:{"direction":"top","width":"20px","height":"20px"}})],1),_c('ButtonElement',{attrs:{"link":""},on:{"click":function($event){return _vm.$emit('sort-desc', header.key)}}},[_c('ArrowIcon',{staticClass:"sort-desc",class:{
              'sort-desc--active': _vm.sortType === ((header.key) + ":desc"),
            },attrs:{"direction":"bottom","width":"20px","height":"20px"}})],1)],1):_vm._e()],2)}),0):_vm._e(),_vm._l((_vm.data),function(row){return _c('div',{key:row.id,class:['table-row', { active: _vm.active.id == row.id }],style:(_vm.calcGrid(_vm.keys)),on:{"click":function($event){return _vm.select(row)}}},_vm._l((_vm.keys),function(cell,key){return _c('div',{key:cell.key,class:['item', { 'right-align': _vm.rightAlign.includes(key) }],style:(_vm.calcGrid(_vm.keys))},[_vm._t(("cell-" + (cell.key)),[(cell.combined)?[_c('div',{staticStyle:{"display":"flex"}},_vm._l((cell.combined),function(a,b){return _c('span',{key:b,ref:"textContainerRef",refInFor:true,staticStyle:{"margin-right":"4px"},attrs:{"id":_vm.generateId(row[cell.key], cell.key)}},[_vm._v(" "+_vm._s(row[a])+" ")])}),0)]:[(_vm.allowedTypes(row[cell.key]))?_c('span',{ref:"textContainerRef",refInFor:true,staticClass:"content",attrs:{"id":_vm.generateId(row[cell.key], cell.key)}},[_vm._v(" "+_vm._s(row[cell.key])+" ")]):_vm._e()]],{"value":row,"header":cell.key,"cell":row[cell.key],"row":row})],2)}),0)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }